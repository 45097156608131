import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './redux/store';
import LoopApiService from './services/network/LoopApiService';
import LoopApiServiceContext from './context/LoopApiServiceContext';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initializeSegment } from './segment';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

WebFont.load({
    google: {
        families: ['Inter:300,400,600,700', 'EB Garamond:300,400,500,700,800']
    }
});
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV
    });
}

initializeSegment();

ReactDOM.render(
    <BrowserRouter>
    <ThemeProvider theme={theme}>
            <React.StrictMode>
                <Provider store={store}>
                    <LoopApiServiceContext.Provider value={LoopApiService}>
                        <App />
                    </LoopApiServiceContext.Provider>
                </Provider>
            </React.StrictMode>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
