class LoopEndpoints {
    getOnCallDoctorDates = (doctorId) => `/slots-getOnCallDoctorDates?doctorId=${doctorId}`;

    getOnCallDoctorSlots = (doctorId, date) => `/slots-docOnCall?doctorId=${doctorId}&date=${date}`;

    bookDoctorSlot = () => '/slots-confirmDocOnCall';

    cancelDoctorSlot = () => '/slots-cancelDocOnCall';

    rescheduleDoctorSlot = () => '/slots-rescheduleDocOnCall';

    addLoopId = '/applicationService-addLoopId';
}

export default new LoopEndpoints();
