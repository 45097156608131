import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import LoopHttpException from "../../exceptions/LoopHttpException";
import LoopEndpoints from "./LoopEndpoints";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class LoopApiService {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app();
    }

    this.captcha = firebase.auth;
    this.auth = firebase.auth();
    this.functions = firebase.app().functions("asia-south1");
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.analytics = firebase.analytics();
    this.BASE_URL = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    this.DEFAULT_TIMEOUT = 30;
  }

  getHttpConfig = (token, verb = "GET") => ({
    method: verb,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  getContentType = (res) => {
    const isJSON =
      res.headers.get("Content-Type")?.startsWith("application/json") || false;

    if (isJSON) {
      return "JSON";
    }

    const isText = res.headers.get("Content-Type")?.startsWith("text") || false;
    if (isText) {
      return "Text";
    }

    return "Unsupported";
  };

  doThrow = async (res, contentType) => {
    // Not 2XX
    if (contentType === "JSON") {
      const result = await res.json();
      let error;
      if (Array.isArray(result) && result.length > 0) {
        error = result[0];
      } else {
        error = result.error;
      }

      const message = error.message ? error.message : "";
      throw new LoopHttpException(res.status, message, res.url);
    } else if (contentType === "Text") {
      // TODO: Check API to see if handled differently
      const error = await res.text();
      const message = error ? error : "";
      throw new LoopHttpException(res.status, message, res.url);
    }

    // Not JSON and not Text
    throw new LoopHttpException(
      res.status,
      "Unsupported Content Type",
      res.url
    );
  };

  processResponse = async (res) => {
    const contentType = this.getContentType(res);

    if (res.ok) {
      if (contentType === "JSON") {
        return await res.json();
      } else {
        return res;
      }
    }

    return this.doThrow(res, contentType);
  };

  api = async ({ endpoint, verb, token, timeout, data }) => {
    const reqConfig = this.getHttpConfig(token, verb);
    const fullUrl = `${this.BASE_URL}${endpoint}`;
    const reqTimeout = timeout || this.DEFAULT_TIMEOUT;
    const controller = new AbortController();
    let finalConfig;

    if (!data) {
      finalConfig = { signal: controller.signal, ...reqConfig };
    } else {
      finalConfig = {
        signal: controller.signal,
        body: JSON.stringify(data),
        ...reqConfig,
      };
    }
    const abort = setTimeout(() => controller.abort(), reqTimeout * 1000);
    const response = await fetch(fullUrl, finalConfig);
    clearTimeout(abort);
    return this.processResponse(response);
  };

  signOut = () => {
    this.auth.signOut();
  };

  signInWithPhoneNumber = async (phoneNumber, recaptchaVerifier) => {
    // Remove +91 from phone number
    await this.checkConsultAllowed(phoneNumber.split("+91")[1]);

    return this.auth
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        return confirmationResult;
      })
      .catch((error) => {
        throw new Error("Failed to send OTP");
      });
  };

  verifyUserExists = async (phoneNumber) => {
    // Remove +91 from phone number
    // phoneNumber = phoneNumber.split("+91")[1];
    const user = await this.db.collection("user").doc(phoneNumber).get();

    if (user.exists) {
      const userObject = user.data();
      if (userObject.userType === "patient") {
        return true;
      }
    }

    return false;
  };

  checkConsultAllowed = async (phoneNumber) => {
    const checkConsultAllowed = this.functions.httpsCallable(
      "checkConsultAllowed"
    );
    const result = await checkConsultAllowed({ phone: phoneNumber });

    if (!result.data.userExists) {
      throw new Error("User does not exist");
    }
  };

  getEcardDownloadURL = (ecardPath) => {
    return this.storage.ref(`Ecards/${ecardPath}`).getDownloadURL();
  };

  getImageDownloadURL = (paths) => {
    return Promise.allSettled(
      paths.map((path) => {
        if (!path) {
          return "";
        }
        return this.storage.ref(path).getDownloadURL();
      })
    );
  };

  getUserProfile = async (userId) => {
    const userData = [];
    try {
      let data = await this.db
        .collection("user")
        .where("active", "==", true)
        .where("mobile", "==", userId)
        .limit(1)
        .get();
      data.forEach((item) => {
        let user = item.data();
        user.id = item.id;
        userData.push(user);
      });
      const user = userData.length ? userData[0] : null;
      if (user) {
        const ecardPath = user ? user.patientEcard : null;
        if (ecardPath) {
          try {
            user.patientEcardUrl = await this.getEcardDownloadURL(ecardPath);
          } catch (err) {
            user.patientEcardUrl = null;
          }
        }
        return user;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error("Error in finding user:" + error);
    }
  };

  getInsuranceData = async () => {
    const getInsuranceData = this.functions.httpsCallable(
      "api-getInsuranceData"
    );

    try {
      const result = await getInsuranceData();
      return result;
    } catch (err) {
      throw new Error(err);
    }
  };

  getDoctorList = async () => {
    const doctorQuerySnapshot = await this.db
      .collection("user")
      .where("active", "==", true)
      .where("userType", "==", "doctor")
      .limit(25)
      .get();

    const doctors = [];
    const headerImagePaths = [];
    const detailImagePaths = [];

    doctorQuerySnapshot.forEach((doc) => {
      const document = doc.data();
      document.id = doc.id;
      doctors.push(document);
      if (document.headerImage) {
        headerImagePaths.push(`UserPhoto/${document.headerImage}`);
      } else {
        headerImagePaths.push("");
      }
      if (document.detailImage) {
        detailImagePaths.push(`UserPhoto/${document.detailImage}`);
      } else {
        detailImagePaths.push("");
      }
    });

    const [headerImageUrls, detailImageUrls] = await Promise.all([
      this.getImageDownloadURL(headerImagePaths),
      this.getImageDownloadURL(detailImagePaths),
    ]);

    for (const [i, doc] of doctors.entries()) {
      doc.headerImageUrl = headerImageUrls[i].value;
      doc.detailImageUrl = detailImageUrls[i].value;
    }

    return doctors;
  };

  getDoctorBySpeciality = async (speciality) => {
    const doctorQuerySnapshot = await this.db
      .collection("user")
      .where("active", "==", true)
      .where("userType", "==", "doctor")
      .where("specialityId", "==", speciality)
      .get();
    const doctors = [];
    const headerImagePaths = [];
    const detailImagePaths = [];

    doctorQuerySnapshot.forEach((doc) => {
      const document = doc.data();
      document.id = doc.id;
      doctors.push(document);
      headerImagePaths.push(document.headerImage);
      detailImagePaths.push(document.detailImage);
    });

    const headerImageUrls = await Promise.all(
      headerImagePaths.map(async (path) => {
        if (!path) {
          return "";
        }
        return this.storage.ref(`UserPhoto/${path}`).getDownloadURL();
      })
    );
    const detailImageUrls = await Promise.all(
      detailImagePaths.map(async (path) => {
        if (!path) {
          return "";
        }
        return this.storage.ref(`UserPhoto/${path}`).getDownloadURL();
      })
    );

    return [doctors, headerImageUrls, detailImageUrls];
  };

  getDoctorById = async (id) => {
    const doctorQuerySnapshot = await this.db.collection("user").doc(id).get();

    const doctor = doctorQuerySnapshot.data();
    doctor.id = doctorQuerySnapshot.id;
    const [headerImageUrl, detailImageUrl] = await Promise.all([
      this.storage.ref(`UserPhoto/${doctor.headerImage}`).getDownloadURL(),
      this.storage.ref(`UserPhoto/${doctor.detailImage}`).getDownloadURL(),
    ]);
    doctor.headerImageUrl = headerImageUrl;
    doctor.detailImageUrl = detailImageUrl;

    return doctor;
  };

  getSpecialities = async () => {
    const specialityQuerySnapshot = await this.db
      .collection("speciality")
      .where("active", "==", true)
      .orderBy("updatedAt", "asc")
      .get();
    const specialities = [];

    specialityQuerySnapshot.forEach((doc) => {
      const document = doc.data();
      specialities.push(document);
    });

    return specialities;
  };

  getVisits = async (patientId) => {
    const visitQuerySnapshot = await this.db
      .collection("visit")
      .where("active", "==", true)
      .where("status", "==", "scheduled")
      .where("patientId", "==", patientId)
      .where("type", "==", "call")
      .get();

    const visits = [];
    const doctors = [];
    visitQuerySnapshot.forEach((doc) => {
      const document = doc.data();
      document.id = doc.id;
      doctors.push(document.doctorId);
      visits.push(document);
    });

    return [visits, doctors];
  };

  getPolicyPlan = async (policyPlanId) => {
    const policyPlanQuerySnapshot = await this.db
      .collection("policyPlan")
      .doc(policyPlanId)
      .get();

    return policyPlanQuerySnapshot.data();
  };

  getDependentInformation = async (userId) => {
    const ecardQuerySnapshot = await this.db
      .collection("user")
      .where("parentId", "==", userId)
      .get();

    const dependents = [];
    const dependentEcards = [];
    ecardQuerySnapshot.forEach((doc) => {
      const document = doc.data();
      document.id = doc.id;
      dependents.push(document);
      if (document.patientEcard || document.ecard) {
        const ecardPath = document.patientEcard || document.ecard;
        dependentEcards.push(`Ecards/${ecardPath}`);
      } else {
        dependentEcards.push("");
      }
    });

    const [dependentEcardsUrl] = await Promise.all([
      this.getImageDownloadURL(dependentEcards),
    ]);

    for (const [i, doc] of dependents.entries()) {
      doc.patientEcardUrl = dependentEcardsUrl[i].value;
    }
    return dependents;
  };

  getSlotDates = async (doctorId) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.getOnCallDoctorDates(doctorId),
      token: idToken,
    });
  };

  getSlots = async (doctorId, date) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.getOnCallDoctorSlots(doctorId, date),
      token: idToken,
    });
  };

  bookDoctorSlot = async (data) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.bookDoctorSlot(),
      verb: "POST",
      token: idToken,
      data,
    });
  };

  cancelDoctorSlot = async (data) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.cancelDoctorSlot(),
      verb: "DELETE",
      token: idToken,
      data,
    });
  };

  rescheduleDoctorSlot = async (data) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.rescheduleDoctorSlot(),
      verb: "PUT",
      token: idToken,
      data,
    });
  };
}

export default new LoopApiService();
