import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 70px;
    height: 70px;
    width: 100vw;
    max-width: 100vw;
    background-color: ${(p) => p.theme.colors.primaryBG};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 1300px) {
        max-width: 100vw;
        justify-content: center;
        padding: 0px 0px;
    }
`;

export const StyledLogo = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
    @media (max-width: 1300px) {
        max-width: 100px;
    }
`;
