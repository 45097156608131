import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

export const fetchPolicyPlan = createAsyncThunk(
  "policyPlan/getPolicyPlan",
  async ({ policyPlanId }) => {
    const result = await LoopApiService.getPolicyPlan(policyPlanId);
    return {
      data: result,
    };
  }
);

const policyPlanSlice = createSlice({
  name: "policyPlan",
  initialState: {
    policy: null,
    state: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchPolicyPlan.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchPolicyPlan.fulfilled]: (state, action) => {
      state.policy = action.payload.data;
      state.status = "succeeded";
    },
    [fetchPolicyPlan.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectPolicyPlan = (state) =>
  state.policyPlan.policy
    ? state.policyPlan.policy.length !== 0
      ? state.policyPlan.policy
      : null
    : null;

export default policyPlanSlice.reducer;
