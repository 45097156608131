import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    loading: true,
  },
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload.user;
      state.loading = action.payload.loading;
    },
  },
});

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
