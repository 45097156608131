import {
    Wrapper,
    PolicySubTitle,
    PolicyTitle,
    TermsTitle,
    TermsUseWrapper,
    TermsWrapper,
    AnchorWrapper,
    Header4,
    Strong,
    Paragraph
} from './styles';

export const PolicyScreen = () => {
    return (
        <Wrapper>
            <PolicyTitle>Hello! We’ve updated our legal policy</PolicyTitle>
            <PolicySubTitle>Accepting the terms are important to keep enjoying the benefits on the app</PolicySubTitle>
            <TermsWrapper>
                <TermsTitle>Terms of Use</TermsTitle>
                <TermsUseWrapper>
                    <div class="legal-header_content">
                        <Paragraph>
                            Welcome to Loop. This document is an electronic record in terms of Information Technology
                            Act, 2000 and published in accordance with the provisions of Rule 3 of the Information
                            Technology (Intermediaries guidelines) Rules, 2011.
                        </Paragraph>
                        <div class="margin-top margin-medium">
                            <div class="legal-header_rich-text w-richtext">
                                <Paragraph>
                                    Invoq Healthcare India Private Limited, a company incorporated under the Companies
                                    Act, 2013 <Strong>(“Us”, “We”, “Our” or “Invoq”)</Strong>
                                    is the author and publisher of the internet resource www.loophealth.com as well as
                                    the software and applications provided by Invoq i.e <Strong>'Loop Health</Strong>.
                                    The website www.loophealth.com and the application Loop Health are collectively
                                    referred to as the <Strong>“Sites”</Strong>.
                                </Paragraph>
                                <Paragraph>
                                    These terms of use apply to all current and former user/s accessing or using Our
                                    Sites, or otherwise engaging with Us through e-mail or other means (hereinafter
                                    referred to as <Strong>“You”</Strong> or <Strong>“Your”</Strong> or
                                    <Strong>“User”</Strong>)
                                </Paragraph>
                                <Paragraph>
                                    These terms of use along with the privacy policy <Strong>("terms")</Strong>
                                    including the applicable policies which are incorporated herein by way of reference,
                                    govern the conditions of Your access and use of the Sites, transaction through the
                                    Sites and use of Services (as defined herein below) hosted or managed remotely
                                    through the Sites. These Terms of Use constitutes a legal and binding contract
                                    between You on one part and Invoq on the other Part.
                                </Paragraph>
                                <Paragraph>
                                    By signing up to use the Sites, downloading, accessing, or installing and using Our
                                    application, You confirm and agree to be bound by these terms. If You do not agree
                                    to be bound by these terms, You are not entitled to access, download or use Our
                                    Sites and are required to delete any versions of the mobile application and any data
                                    downloaded by You while using the Sites.
                                </Paragraph>
                                <Header4>1. General</Header4>
                                <ul>
                                    <Strong>1.1</Strong> The Sites are a platform that facilitates (i) diagnostic
                                    services being offered by various third party diagnostic centers
                                    <Strong>(“Third Party Labs”)</Strong>; (ii) medical consultancy services being
                                    offered by third party independent doctors <Strong>(“Third Party Doctors”)</Strong>
                                    (iii) medical consultancy services being offered by third party hospitals, nursing
                                    homes, clinics, wellness centres and other entities
                                    <Strong>(“Third Party Healthcare Providers”)</Strong>; (iii) Health insurance
                                    services being offered by various third party insurance companies and Third Party
                                    Administrators <Strong>(“Insurance Companies”)</Strong> (iv) other services as may
                                    be introduced from time to time (hereinafter collectively referred to as
                                    “Services”). The Third Party Labs, Third Party Doctors, Third Party Healthcare
                                    Providers and the Insurance Companies are collectively referred to as the
                                    <Strong>“Third Party Service Providers”</Strong>.
                                    <br />
                                    <Strong>1.2</Strong> You agree and acknowledge that Our Sites is a platform that You
                                    and Third Party Service Providers utilize to meet and interact with another for
                                    their transactions. Invoq is not and cannot be a party to or save as except as may
                                    be provided in these Terms of Use, control in any manner, any transaction between
                                    You and the Third Party Service Providers.
                                    <br />
                                    <Strong>1.3</Strong> We reserve the right to change or modify these Terms of Use or
                                    any policy or guideline of the Sites including the Privacy Policy, at any time and
                                    in Our sole discretion. Any changes or modifications will be effective immediately
                                    upon posting the revisions on the Sites. Your continued use of the Sites will
                                    confirm Your acceptance of such changes or modifications; therefore, You should
                                    frequently review these Terms of Use and applicable policies to understand the terms
                                    and conditions that apply to Your use of the Sites and Services.
                                    <br />
                                    <Strong>1.4</Strong> By visiting the Sites or accepting these Terms of Use, You
                                    represent and warrant to Us that You are competent to contract and that You have the
                                    right, authority and capacity to use the Sites and agree to and abide by these
                                    terms.
                                    <br />
                                    <Strong>1.5</Strong> We authorize You to view and access the content available on
                                    the Our Sites solely for the purposes of availing the Services. The contents on the
                                    Sites including information, text, blogs, articles, graphics, images, logos, button
                                    icons, software code, design, and the collection, arrangement and assembly of
                                    content, contains Third Party Service Providers' content
                                    <Strong>(“Third Party Content”)</Strong>
                                    as well as in-house content provided by Us including without limitation,
                                    information, text, blogs, articles, copy, logos, button icons, software code, audio,
                                    video, photographs, illustrations, graphics and other visuals
                                    <Strong>(“Our Content”)</Strong> (collectively, <Strong>“Content”</Strong>). The
                                    Content available on the Sites is for general information purposes only and does not
                                    constitute either an advertisement or promotion or advice of any kind whatsoever on
                                    the Sites or any professional medical advice, diagnosis, treatment or recommendation
                                    of any kind. Also, You acknowledge and understand that Invoq provides no warranty or
                                    representation with respect to the authenticity of the information provided on the
                                    Sites and You must do Your own check. Our Content is the property of Invoq and is
                                    protected under copyright, trademark and other applicable law(s).
                                    <br />
                                    <Strong>1.6</Strong> Compliance with these terms would entitle You to a personal,
                                    non-exclusive, non-transferable, limited privilege to access and transact on the
                                    Sites.
                                </ul>
                                <br />
                                <Header4>2. User account, password and security</Header4>
                                <ul>
                                    <Strong>2.1</Strong> For the purposes of availing the Services and/or transacting
                                    with the Third Party Service Providers through the Sites, You are required to obtain
                                    registration, in accordance with the procedure established by Us in this regard. As
                                    part of the registration process, We may collect the following Information from You
                                    including without limitation Your name, User ID, email address, address, gender,
                                    age, contact number, number of employees, health or medical history and data,
                                    insurance data, personnel details, password chosen by the User, financial account
                                    information and other details as You may provide.
                                    <br />
                                    <Strong>2.2</Strong> The Services will be provided depending upon the type of
                                    membership plans You have chosen and registered.
                                    <br />
                                    <Strong>2.3</Strong> A registered User ID can be utilized by the person whose
                                    details have been provided and such person can add up to three of his family members
                                    or friends to use the Sites. You represent and warrant that such family member or
                                    friend, as the case may be has consented to such addition. Please note that You
                                    shall be responsible and liable for any discrepancy/error in the genuineness,
                                    correctness, accurate, truthful, current and complete information of such family
                                    members or friends that You add to use the Sites. Invoq and its Entities shall not
                                    have any liability or responsibility in this regard.
                                    <br />
                                    <Strong>2.4</Strong> A registered user, being also a parent or legal guardian of a
                                    person 'incompetent to contract' such as minors or persons with unsound mind, would
                                    be permitted to access and use the Sites for the purposes of procuring the Services,
                                    on behalf of such persons.
                                    <br />
                                    <Strong>2.5</Strong> You agree and acknowledge that You would (i) provide accurate,
                                    truthful, current and complete information when creating Your account and in all
                                    Your dealings through the Sites; (ii) maintain and promptly update Your account
                                    information; (iv) maintain the security of Your account by not sharing Your password
                                    with others and restricting access to Your account and Your computer; (v) promptly
                                    notify Us if You discover or otherwise suspect any security breaches relating to the
                                    Sites; and (vi) take responsibility for all the activities that occur under Your
                                    account and accept all risk of unauthorized access.
                                    <br />
                                    <Strong>2.6</Strong> We, at Our sole discretion, with or without any reason, reserve
                                    the right to permanently or temporarily suspend Users, to bar their use and access
                                    of the Sites, at any time while We investigate complaints or alleged violations of
                                    these terms or any Services, or for any other reason.
                                </ul>
                                <Header4>3. Use of the Services and the Sites</Header4>
                                <ul>
                                    <Strong>3.1 Medical consultancy services</Strong>
                                    <ul>
                                        <Strong>3.1.1</Strong> You acknowledge that the Third Party Doctors and Third
                                        Party Healthcare Providers empaneled and/or associated with Us are independent
                                        contractors and thereby We have an independent contractor relationship with such
                                        Third Party Doctors and Third Party Healthcare Providers and therefore, in no
                                        event, We will be directly or vicariously liable for any advice or medical
                                        consultancy, treatment or any loss arising therefrom that the Third Party
                                        Doctors and/or Third Party Healthcare Providers may provide to You or You may
                                        avail as part of the Services.
                                        <br />
                                        <Strong>3.1.2</Strong> The opinions, statements, diagnosis, prognosis, answers,
                                        prescriptions, in-person consultation, treatment and tele-consultations
                                        (collectively <Strong>“Consultation”</Strong>) provided by Third Party Doctors
                                        and/or any medical practitioner, consultant, specialist, professional or any
                                        other person associated/employed by Third Party Healthcare Providers through Our
                                        Sites and/or clinics are solely the individual and independent opinions and
                                        statements of such Third Party Doctors and Third Party Healthcare Providers and
                                        do not reflect the opinions of Invoq, its Entities and affiliates..
                                        <br />
                                        <Strong>3.1.3</Strong> You acknowledge that although some of the content, blog,
                                        articles, text, data, graphics, images, information, suggestions, guidance, and
                                        other material
                                        <Strong>(collectively, “information”)</Strong> that is provided to You on the
                                        Sites (including Information provided in direct response to Your questions or
                                        postings) may be provided by individuals in the medical profession, the
                                        provision of such information does not create a doctor/medical
                                        professional-patient relationship, but is provided to inform You on various
                                        medical conditions, medical diagnosis and treatment and it does not constitute a
                                        direct medical diagnosis, treatment or prescription. Everything on the Sites
                                        should be used for information purposes only.
                                        <br />
                                        <Strong>3.1.4</Strong> We do not recommend or endorse any specific Third Party
                                        Doctor(s), Third Party Healthcare Providers, Third Party Labs, tests, products,
                                        policies, procedures, opinions, or other information that may be mentioned on
                                        the Sites. Reliance on any information provided on the Sites is solely at Your
                                        own risk. In case of any medical emergency, kindly contact Your nearest
                                        doctor/hospital or any related helpline.
                                        <br />
                                        <Strong>3.1.5</Strong> You take full responsibility for ensuring that the
                                        information submitted is accurate and We shall not make any effort to validate
                                        any information provided by You for using the Services with respect to content,
                                        correctness or usability. We and/or the Third Party Service Providers, with an
                                        intention to provide the best Services possible, could ask You to share more
                                        information as and when needed.
                                        <br />
                                        <Strong>3.1.6</Strong> The inclusion of professionals, specialists, Third Party
                                        Doctors and/or Third Party Healthcare Providers on the Sites does not imply
                                        recommendation or endorsement of such professionals, specialists and/ or Third
                                        Party Doctors, Third Party Healthcare Providers nor is such information intended
                                        as a tool for verifying the credentials, qualifications, licenses, permits,
                                        legal status or abilities of any professional, specialists, Third Party Doctors
                                        and/or Third Party Healthcare Providers contained therein. Such information is
                                        provided on an 'as-is' basis and We disclaim all warranties, either express or
                                        implied, including but not limited to the implied warranties of merchantability
                                        and fitness for particular purpose.
                                        <br />
                                        <Strong>3.1.7</Strong> Invoq, (the directors and the employee staff of Invoq,
                                        its Entities and affiliates) and Third Party Service Providers who offer the
                                        Services accept no responsibility for any medical, legal or financial events or
                                        outcomes related to the Services availed through the use of the Sites.
                                        <br />
                                        <Strong>3.1.8</Strong> We make no warranty that the Services will meet Your
                                        requirements, or that the Service(s) will be uninterrupted, timely, secure or
                                        error free. This includes loss of data or any service interruption caused by Our
                                        employees.
                                        <br />
                                        <Strong>3.1.9</Strong> You shall be solely liable for Your dealings and
                                        interaction with patients, Third Party Doctors or Third Party Healthcare
                                        Providers (as the case may be) contacted or managed through the Sites and We
                                        shall have no liability or responsibility in this regard. We do not guarantee or
                                        make any representation with respect to the correctness, completeness or
                                        accuracy of the information or detail provided by such client, customer,
                                        patient, User or any Third Party Service Provider through the Sites. The
                                        Services should not be used for emergency appointment purposes and if used, You
                                        agree to take full responsibility and liability for the consequences thereof.
                                        <br />
                                        <Strong>3.1.10</Strong> Invoq may, at its sole discretion, with or without any
                                        reason, suspend User's, Third Party Doctor's or Third Party Healthcare
                                        Provider's ability to use or access the Sites. Invoq has the right to edit
                                        profiles of Third Party Doctors and/or Third Party Healthcare Providers to make
                                        them more suitable for patient/ Users searches on the Sites. If Third Party
                                        Doctor, Third Party Healthcare Provider and/ or Users find any wrong information
                                        on the Sites in relation to such Third Party Doctor, Third Party Healthcare
                                        Provider and/ or User, contact Us immediately for such corrections. Invoq shall
                                        have no liability or responsibility in this regard.
                                        <br />
                                        <Strong>3.1.11</Strong> As with any medical procedure, there are potential risks
                                        associated with using the Services. By using the Services, You agree to abide by
                                        these Terms of Use, Privacy Policy and the risks associated with medical
                                        Consultation.
                                        <br />
                                        <Strong>3.1.12</Strong> By requesting a Consultation through Third Party Doctors
                                        and/or Third Party Healthcare Providers, You further acknowledge and agree that-
                                        <br />
                                        <ul>
                                            <Strong>a)</Strong> in case where there is a difference of opinion among
                                            Third Party Doctors or Third Party Healthcare Providers and some other
                                            doctor, who is not Our Third Party Service Provider, whom You have
                                            consulted, You would bear the responsibility to decide on online or offline
                                            consultation, or procedure, and/or treatment;
                                            <br />
                                            <Strong>b)</Strong> the Third Party Doctor and/or Third Party Healthcare
                                            Providers is reliant on Information provided by You and hence, any
                                            Information demonstrated to have been falsified, misleading, inaccurate or
                                            incomplete will immediately render the opinion/consultation and all details
                                            therein null and void;
                                            <br />
                                            <Strong>c)</Strong> in rare cases, the Third Party Doctor may feel that the
                                            query may not be answerable without physically examining the patient/ Users
                                            and the consultation may be refused forthwith;
                                            <br />
                                            <Strong>d)</Strong> in very rare instances, security protocols could fail,
                                            causing a breach of privacy of personal medical information;
                                            <br />
                                            <Strong>e)</Strong> delays in medical evaluation and answers could occur due
                                            to deficiencies or failures of the Third Party Service Providers and in that
                                            even, Invoq shall not be liable for any loss or damage due to such
                                            deficiencies or failures.
                                        </ul>
                                        <Strong>3.1.13</Strong> The absence of any information and/or warning pertaining
                                        to the medical consultancy services shall not be considered and assumed as an
                                        implied assurance of Invoq.
                                        <br />
                                        <br />
                                    </ul>
                                    <Strong>3.2 Diagnostic Services</Strong>
                                    <ul>
                                        <Strong>3.2.1</Strong> We provide Services through Our Sites as a marketplace
                                        and facilitate the Users to avail diagnostic test/ packages offered by Third
                                        Party Labs through Us. We shall not be responsible for any sample collected,
                                        tests conducted, reports generated and/or any other related services provided by
                                        the Third Party Labs and do not deal with any of Third Party Labs' client or
                                        patient managed by Third Party Labs through the Sites. We only provide
                                        facilitation Services to the Users through the Sites. Use of the Sites may
                                        require the Third Party Labs to use software and the Third Party Labs have to
                                        ensure the procurement of such software from the concerned providers. User and
                                        the Third Party Labs agree to use the Sites and the materials provided therein
                                        only for purposes that are permitted by: (a) these terms; and (b) any applicable
                                        law(s), regulation or generally accepted practices or guidelines in the relevant
                                        jurisdictions.
                                        <br />
                                        <Strong>3.2.2</Strong> Notwithstanding anything to the contrary contained
                                        herein, Third Party Labs alone shall be liable for Third Party Labs' dealings
                                        and interaction with the Users who avail the services of the Third Party Labs or
                                        diagnostic centres contacted or managed through Us and We shall have no
                                        liability or responsibility in this regard. We do not guarantee or make any
                                        representation with respect to the correctness, completeness or accuracy of the
                                        information or details provided by such User, Third Party Labs or any diagnostic
                                        centre or any third party. The Services should not be used for emergency
                                        appointment purposes and if used, You agree to take full responsibility and
                                        liability for the consequences thereof.
                                        <br />
                                        <Strong>3.2.3</Strong> Invoq may, at its sole discretion, with or without any
                                        reason, suspend Third Party Labs or Users ability to use or access the Sites.
                                        Invoq has the right to edit profiles of Third Party Labs to make them more
                                        suitable for package searches on the Sites. If Third Party Labs and/ or Users
                                        find any wrong information on the Sites in relation to such Third Party Labs
                                        and/ or User, contact Us immediately for such corrections. Invoq shall have no
                                        liability or responsibility in this regard.
                                        <br />
                                        <Strong>3.2.4</Strong> You acknowledge and agree that You bear the
                                        responsibility to decide on the Third Party Lab/s from which You would like to
                                        avail the diagnostic services. The inclusion or availability of Third Party Labs
                                        on the Sites does not imply advertisement, recommendation or endorsement of such
                                        Third Party Labs.
                                        <br />
                                        <Strong>3.2.5</Strong> The absence of any information and/or warning pertaining
                                        to the diagnostics services shall not be considered and assumed as an implied
                                        assurance of Invoq.
                                        <br />
                                        <br />
                                    </ul>

                                    <Strong>3.3 Health Insurance Services</Strong>
                                    <ul>
                                        <Strong>3.3.1</Strong> Invoq arranges/facilitates services to those Users who
                                        are interested in obtaining Health Insurance Cover from certain Third Party
                                        Insurance Companies.
                                        <br />
                                        <Strong>3.3.2</Strong> We shall not be responsible for any delay or deficiency
                                        in services, including without limitation, delay in processing/settlement of
                                        claims, collection of premium, mishandling of Your data, mis-selling of
                                        insurance policies, spurious calls, non-settlement of claims, suggestions on
                                        insurance –related matters and other health insurance Services provided by Third
                                        Party Insurance Companies.
                                        <br />
                                        <Strong>3.3.3</Strong> Notwithstanding anything to the contrary contained
                                        herein, Third Party Insurance Companies alone shall be liable for Third Party
                                        Insurance Companies' dealings and interaction with the Users who avail the
                                        services of the Third Party Insurance Companies contacted or managed through Us
                                        and We shall have no liability or responsibility in this regard. We do not
                                        guarantee or make any representation with respect to the correctness,
                                        completeness or accuracy of the information or details provided by such User,
                                        Third Party Insurance Companies, Third Party Administrators or any third party.
                                        <br />
                                        <Strong>3.3.4</Strong> Invoq may, at its sole discretion, with or without reason
                                        suspend Third Party Insurance Companies or Users ability to use or access the
                                        Sites. Invoq has the right to edit profiles of Third Party Insurance Companies
                                        to make them more suitable for package searches on the Sites. If Third Party
                                        Insurance Companies and/ or Users find any wrong information on the Sites in
                                        relation to such Third Party Insurance Companies and/ or User, contact Us
                                        immediately for such corrections. Invoq shall have no liability or
                                        responsibility in this regard.
                                        <br />
                                        <br />
                                    </ul>

                                    <Strong>3.4 Other Services</Strong>
                                    <ul>
                                        <Paragraph>
                                            Our Services are constantly evolving. We may partner with third parties,
                                            such as corporates and organizations where You are working and who have
                                            already registered on our Sites <Strong>(“Corporate”)</Strong>
                                            to launch new contests, surveys, programs and events for their employees and
                                            personnel. In such case, if You participate in any survey, contest, program
                                            or event, We collect information You submit such as your name, contact
                                            number, e-mail address and such other information as may be required for the
                                            purpose of survey, contest, program or event. We collect such information so
                                            that We can help You to participate in contests, surveys, programs and
                                            events and enable the rewards such as money, free services, discounts or
                                            vouchers etc as may be decided and given by the Corporate
                                            <Strong>(“Rewards”)</Strong> We may store, use and disclose such information
                                            to the Corporate to help You participate, determine Your eligibility for
                                            Rewards and enable Rewards given to the eligible persons by the Corporate.
                                            Please be assured that We will share Your information collected through Your
                                            participation in survey, contest, program and/or event only with the
                                            Corporate where You are currently working at the time of participation in
                                            the survey, contest, program and/or event for the aforesaid purpose.
                                        </Paragraph>
                                        <Paragraph>
                                            You understand, agree and acknowledge that We merely co-ordinate with the
                                            Corporate to launch new contests, surveys, programs and events and We do not
                                            make any guarantee, representations or endorsements or implied or express
                                            warranties with respect to any service, survey, contest, program, event or
                                            Reward. We have no power or control over the decision as to Your
                                            eligibility/ entitlement as well as eligibility/entitlement of other
                                            personnel of such Corporates for any Rewards and such decision shall be made
                                            solely by the Corporate. We shall have no liability or responsibility in
                                            this regard. You hereby expressly release Us and/or Our affiliates and/or
                                            any of Our officers and representatives from any cost, damage, liability,
                                            expense or other consequence of any Corporate actions/inactions and
                                            specifically waiver any claims or demands that You may have in this behalf
                                            under any statute, contract or otherwise.
                                        </Paragraph>
                                        <Paragraph>
                                            Integration with other applications: In some cases, We may have to integrate
                                            with other applications such as Apple Health and Google Fit. If You choose
                                            to have Our Sites integrate with Apple Health or Google Fit, the app will
                                            have access only to such information as may be necessary for the contests,
                                            surveys, programs and events such as the number of steps taken by You during
                                            the period of contests, surveys, programs and events. Such data read from
                                            Apple Health or Google fit is used solely for visualizing your data within
                                            Our Sites. Note that You can opt out of this at any point by getting in
                                            touch with Us. Further, You can always contact Us if You want all of your
                                            anonymous data to be removed from Our servers completely. We will never use
                                            any User data collected from Apple Health and Google Fit for marketing or
                                            advertising purposes.
                                        </Paragraph>
                                    </ul>
                                </ul>
                                <br />
                                <Header4>4. Payment, fees and taxes</Header4>
                                <ul>
                                    <Strong>4.1</Strong> Registration on the Sites and availing the Services is subject
                                    to Your payment of the applicable fees/charges, depending upon the subscription plan
                                    chosen by You and the Services You wish to avail. You agree to make payments
                                    directly to the respective Third Party Insurance Companies for purchase of health
                                    insurance policies/plans and Services from such Third Party Insurance Companies. You
                                    agree and acknowledge that You shall not hold Invoq and its affiliates responsible
                                    for any loss or damage caused to You during the process, due to any acts or omission
                                    on the part of Third Party Service Providers for any actions/ omissions which are
                                    beyond the control of Invoq.
                                    <br />
                                    <Strong>4.2</Strong> In case of medical consultation and diagnostic services being
                                    availed through Our Sites, wherever applicable, You shall make timely payments for
                                    availing the Services of the Third Party Doctors, Third Party Healthcare Providers
                                    and/or Third Party Labs, as the case may be.We shall not be liable for any loss or
                                    damage caused to the User as a consequence of delay in the Services due to
                                    non-payment of the applicable fees/charges by the User. Payment once made will be
                                    refunded as per the refund policy, as may be in effect.
                                    <br />
                                    <Strong>4.3</Strong> Each User / Third Party Service Providers are solely
                                    responsible for payment of all taxes, legal compliances, statutory registrations and
                                    reporting. We are in no way responsible or liable for any of such taxes,
                                    compliances, registrations and reporting except for Our own taxes, compliances,
                                    registrations and reporting.
                                    <Strong>4.4</Strong> 4.4 Invoq reserves the right to modify the fee/payment
                                    structure/subscription plans by providing on the Sites, which shall be considered as
                                    valid and agreed communication.
                                </ul>
                                <Header4>5. User Conduct</Header4>
                                <ul>
                                    <Strong>5.1</Strong> You agree, undertake and confirm to use Our Sites only to post
                                    and upload content that is proper. By way of examples, and not as limitation, You
                                    shall not host, display, upload, modify, publish, transmit, update or share any
                                    information which-
                                    <br />
                                    <ul>
                                        <Strong>a)</Strong> belongs to another person and to which You do not have any
                                        right;
                                        <br />
                                        <Strong>b)</Strong> is grossly harmful, harassing, blasphemous, defamatory,
                                        obscene, pornographic, invasive of another's privacy, hateful, or racially,
                                        ethnically objectionable, disparaging, relating or encouraging money laundering
                                        or gambling, or otherwise unlawful in any manner whatsoever;
                                        <br />
                                        <Strong>c)</Strong> is false and/or misleading in any way;
                                        <br />
                                        <Strong>d)</Strong> infringes upon or violates any third party rights,
                                        including, but not limited to, intellectual property rights, rights of privacy
                                        or rights of publicity;
                                        <br />
                                        <Strong>e)</Strong> tries to gain unauthorized access to the Sites or to
                                        profiles, blogs, communities, account information or other areas or solicits
                                        passwords or personal identifying information for commercial or unlawful
                                        purposes from other users;
                                        <br />
                                        <Strong>f)</Strong> impersonates another person;‍
                                        <br />
                                        <Strong>g)</Strong> engages in commercial activities and/or sales, without Our
                                        prior written consent ;
                                        <br />
                                        <Strong>h)</Strong> interferes with another use's use and enjoyment of Our
                                        Sites;
                                        <br />
                                        <Strong>i)</Strong> violates any law for the time being in force;
                                        <br />
                                        <Strong>j)</Strong> contains viruses, spams, corrupted files or any other
                                        software which may potentially damage Our Sites;
                                        <br />
                                        <Strong>k)</Strong> threatens the unity, integrity, defence, security or
                                        sovereignty of India, friendly relations with foreign states, or public order or
                                        causes incitement to the commission of any cognisable offence or prevents
                                        investigation of any offence or is insulting any other nation;
                                        <br />
                                        <Strong>l)</Strong> violates, abuses, or exploits any of the terms of Our Sites.
                                    </ul>
                                    <Strong>5.2</Strong> You are also prohibited from:
                                    <br />
                                    <ul>
                                        <Strong>a)</Strong> violating or attempting to violate the integrity or security
                                        of the Sites or any Content;
                                        <br />
                                        <Strong>b)</Strong> transmitting any information (including job posts, messages
                                        and hyperlinks) on or through the Sites that is disruptive or competitive to the
                                        provision of services by Invoq;
                                        <br />
                                        <Strong>c)</Strong> intentionally submitting on the Sites any incomplete, false
                                        or inaccurate information;
                                        <br />
                                        <Strong>d)</Strong> making any unsolicited communications to other Users,
                                        patients etc;
                                        <br />
                                        <Strong>e)</Strong> attempting to decipher, decompile, disassemble or reverse
                                        engineer any part of the Sites; and
                                        <br />
                                        <Strong>f)</Strong> copying or duplicating in any manner any information
                                        available from the Sites;
                                    </ul>
                                    <Strong>5.3</Strong> In case of non-compliance with any applicable law(s), rules or
                                    regulations, or these Terms of Use or the Privacy Policy by a User, Invoq has the
                                    right to immediately terminate the access or usage rights of the User to the
                                    Services and to immediately remove non-compliant information from the Sites.
                                </ul>
                                <Header4>6. Liability</Header4>
                                <ul>
                                    <Strong>6.1</Strong> The Services provided by Invoq or any of its licensors or
                                    providers or Third Party Service Providers are provided 'as is', as available, and
                                    without any warranties or conditions (express or implied, including the implied
                                    warranties of merchantability, accuracy, fitness for a particular purpose, title and
                                    non-infringement, arising by statute or otherwise in law or from a course of dealing
                                    or usage or trade). Invoq does not provide or make any representations, warranties
                                    or guarantees, express or implied about the Sites or the Services. The Users and/or
                                    Third Party Service Providers are collectively referred to as
                                    <Strong>“Other Parties”</Strong>. To the fullest extent permitted by applicable
                                    law(s), Invoq and its Entities disclaims all liability arising out of the Other
                                    Parties' use or reliance upon the Sites, the Services, the Content, representations
                                    and warranties made by the Other Parties on the Sites or any loss arising out of the
                                    manner in which the Services have been rendered.
                                    <br />
                                    <Strong>6.2</Strong> Invoq shall not be responsible for the mishaps/missed Services
                                    due to no service/no show from the Other Parties; Invoq shall not be responsible for
                                    any error, shortcoming, fault or deficiency in any of the Services being provided by
                                    the Third Party Service Providers.
                                    <br />
                                    <Strong>6.3</Strong> Invoq assumes no responsibility, and shall not be liable for,
                                    any damages to, or viruses that may infect Other Parties 'device or equipment on
                                    account of the Other Parties' access to, use of, or browsing the Sites or the
                                    downloading of any material, data, text, images, video content, or audio content
                                    from the Sites. If any of the Other Party is dissatisfied with the Sites, the sole
                                    remedy of such Other Party(s) is to discontinue using the Sites.
                                    <br />
                                    <Strong>6.4</Strong> To the maximum extent permitted by applicable law(s), Invoq,
                                    its affiliates, independent contractors, service providers, consultants, licensors,
                                    agents, and representatives, and each of their respective directors, officers or
                                    employees (“Entities”), shall not be liable for any direct, indirect, special,
                                    incidental, punitive, exemplary or consequential damages, or any other damages of
                                    any kind, arising from, or directly or indirectly related to (i) the use of, or the
                                    inability to use, the Sites or the content, materials and functions related thereto;
                                    (ii) User's provision of information via the Sites; even if such Entities has been
                                    advised of the possibility of such damages.
                                    <br />
                                    <Strong>6.5</Strong> In no event shall the Entities be liable for, or in connection
                                    with, (i) the provision of, or failure to provide, all or any products, policies or
                                    Services by a Third Party Service Provider to any User; or (ii) any comments or
                                    feedback given by any of the Users in relation to the goods or services provided by
                                    any Third Party Service Providers; or (ii) any content posted, transmitted,
                                    exchanged or received by or on behalf of any User, Third Party Service Providers or
                                    other person on or through the Sites.
                                    <br />
                                    <Strong>6.6</Strong> With respect to the Doctor Consultation Services, Invoq may
                                    decide the Third Party Doctor to whom the query should be directed based on the
                                    information shared by the User. However, in no event the Entities shall be held
                                    liable for the losses attributable to such decision making and in no event shall the
                                    Entities be liable for any Consultation provided and/or e-prescription issued by the
                                    Third Party Doctor by using the interface of online/offline medical consultancy.
                                    <br />
                                    <Strong>6.7</Strong> In no event shall the total aggregate liability of the Entities
                                    to any Other Parties for all claims, demands, damages, losses, causes of action
                                    (whether in contract or tort, including, but not limited to negligence, strict
                                    liability, product liability or otherwise), costs, expenses whatsoever arising from
                                    these Terms of Use, Privacy Policy or any Other Parties' use of the Sites exceed an
                                    aggregate amount of INR 1000/- (Indian Rupees One Thousand only). Invoq accepts no
                                    liability for any errors or omissions on behalf of the Other Parties.
                                    <br />
                                    <Strong>6.8</Strong> In no event shall the Entities be liable for failure and/or
                                    deficiency on the part of the Users or Third Party Service Providers to provide
                                    agreed Services or to make himself/ herself available at the appointed time,
                                    cancellation, delay or re-scheduling of appointments as the case may be. In no event
                                    shall the Entities be liable for any comments or feedback given by any of the Users
                                    in relation to the Services provided by a Third Party Service Providers.
                                    <br />
                                    <Strong>6.9</Strong> The Other Parties agree to defend, indemnify, keep indemnified
                                    and hold harmless Invoq and the Entities from and against any and all claims,
                                    demands, losses, liability, damages, costs and/or expenses (including, but not
                                    limited to, reasonable attorney fees and costs) arising from or related to (a) Other
                                    Parties' access to or use of the Sites; (b) Other Parties' violation of these Terms
                                    of Use or any applicable law(s); (c) Other Parties' violation of any rights of
                                    another person/ entity, including infringement of their intellectual property
                                    rights; or (d) Other Parties' conduct in connection with the Services through the
                                    Sites.
                                    <br />
                                    <br />
                                </ul>
                                <Header4>7. Modification of the Sites</Header4>
                                <Paragraph>
                                    Invoq reserves the right to modify or discontinue, temporarily or permanently, the
                                    Sites or any features or portions thereof without prior notice. Other Parties agree
                                    that Invoq and/or Entities will not be liable for any modification, suspension or
                                    discontinuance of the Sites or any other part thereof.
                                </Paragraph>
                                <br />
                                <Header4>8. Intellectual property rights</Header4>
                                <Paragraph>
                                    All the intellectual property used on the Sites except those which have been
                                    identified as the intellectual properties of the Other Parties shall remain the
                                    exclusive property of Invoq. The Other Parties agree not to circumvent, disable or
                                    otherwise interfere with security related features of the Sites or features that
                                    prevent or restrict use or copying of any materials or enforce limitations on use of
                                    the Sites or the materials therein. The materials on the Sites or otherwise may not
                                    be modified, copied, reproduced, distributed, republished, downloaded, displayed,
                                    sold, compiled, posted or transmitted in any form or by any means, including but not
                                    limited to, electronic, mechanical, photocopying, recording or other means.
                                </Paragraph>
                                <br />
                                <Header4>9. Force Majeure</Header4>
                                <Paragraph>
                                    For the purpose of this clause, “Force Majeure” means and includes any Vis Major
                                    (act of God) and also any circumstance beyond the reasonable control of the Parties
                                    hereto, including without limitation, the following: any act of nature or the public
                                    enemy, accident, explosion, fire, storm, earthquake, flood, drought, perils of the
                                    sea, casualty, pandemic, strikes, lock-outs, labor troubles, riots, sabotage,
                                    embargo, war (whether or not declared), governmental laws, regulations, orders, or
                                    decrees, unavailability of raw material or seizure.
                                </Paragraph>
                                <Paragraph>
                                    Other Parties agree and acknowledge that Invoq shall not be liable for any loss or
                                    damage caused to the Other Parties as a result of a Force Majeure event. In the
                                    event of Force Majeure, Invoq, will depending on whose performance has been impacted
                                    under the Terms of Use, shall give notice to the Other Party(s) of the facts which
                                    constitute the Force Majeure event.
                                </Paragraph>
                                <br />
                                <Header4>10. Termination</Header4>
                                <Paragraph>
                                    Other Parties agree that Invoq, with or without any reason, may immediately
                                    terminate the Other Parties' access to the Sites, without prior notice. Without
                                    limiting the foregoing, Invoq may terminate or temporarily suspend the User or Third
                                    Party Service Provider's access to the Sites for any reason, including without
                                    limitation a) breach or violation of any of the contractual obligations agreed with
                                    Other Parties, including these terms b) there is a request by law enforcement or
                                    other government agencies, or c) in case of unexpected technical issues or problems.
                                    Other Parties agree that all terminations of access to the Sites shall be made at
                                    the sole discretion of Invoq and that Invoq shall not be liable in any manner
                                    whatsoever either to such Other Parties or any third party for any termination of
                                    access to the Sites.
                                </Paragraph>
                                <br />
                                <Header4>11. Severability</Header4>
                                <Paragraph>
                                    In the event that any provision of these terms is held unenforceable, the validity
                                    or enforceability of the remaining provisions will not be affected, and the
                                    unenforceable provision will be replaced with an enforceable provision that comes
                                    close to the intention underlying the unenforceable provision.
                                </Paragraph>
                                <br />
                                <Header4>12. Governing Law and Dispute Resolution</Header4>
                                <Paragraph>
                                    These terms are governed by the laws of India without giving effect to any
                                    principles of conflict of laws. Any legal action or proceeding related to Other
                                    Party(s) access to, or use of, the Sites and/or Services or these Terms of Use shall
                                    be subject to the exclusive jurisdiction of the courts at Pune, Maharashtra, India.
                                </Paragraph>
                                <br />
                                <Header4>13. Grievance Officer</Header4>
                                <Paragraph>
                                    In accordance with Information Technology Act 2000, the rules made there under and
                                    the Consumer Protection (E-Commerce) Rules, 2021, the contact details of the
                                    Grievance Officer are provided below:
                                    <br />
                                    Name: Mr. Ryan Singh
                                    <br />
                                    Invoq Healthcare India Private Limited
                                    <br />
                                    Flat No. 9 Plot No. 17, Nishigandha, Senapati Bapat Road, Near Navrajasthan Society,
                                    Pune 411016
                                    <br />
                                    Email: <a href="mailto:info@loophealth.com">info@loophealth.com</a>
                                    <br />
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </TermsUseWrapper>
                <br />
                <TermsTitle>Privacy Policy</TermsTitle>
                <TermsUseWrapper>
                    <div class="legal-header_content">
                        <Paragraph>
                            Invoq Healthcare India Private Limited, a company incorporated under the Companies Act, 2013
                            <Strong>(“Us”, “We”, “Our” or “Invoq”)</Strong> is the author and publisher of the internet
                            resource www.loophealth.com as well as the software and applications provided by Invoq i.e
                            <Strong>'Loop Health'</Strong>. The website www.loophealth.com and the application Loop
                            Health are collectively referred to as the “Sites”.
                        </Paragraph>
                        <div class="margin-top margin-medium">
                            <div class="legal-header_rich-text w-richtext">
                                <Paragraph>
                                    This Privacy Policy applies to all current and former user/s accessing or using Our
                                    Sites, or otherwise engaging with us through email or other means (hereinafter
                                    referred to as <Strong>“You”</Strong> or <Strong>“Your”</Strong> or
                                    <Strong>“User”</Strong>). By visiting Our Sites and/or using or accessing all or any
                                    portion of the Services (as defined in the Terms of Use) in any manner, You accept
                                    all of the terms and conditions of this Privacy Policy. Your continued access of the
                                    Sites shall be deemed to be Your approval and acceptance to all the terms and
                                    conditions of this Privacy Policy and the changes thereof. You acknowledge and agree
                                    that We may collect, use, and share Your information in accordance with the terms
                                    and conditions of this Privacy Policy.
                                </Paragraph>
                                <Paragraph>
                                    This Privacy Policy sets out how Invoq uses and protects any Information that You
                                    give to Invoq while using the Sites. Invoq is committed to ensuring that Your
                                    privacy is protected. Invoq may change this Privacy Policy from time to time by
                                    updating this page at its sole discretion. You should check this page from time to
                                    time to ensure that You are updated with any changes.
                                </Paragraph>
                                <Paragraph>
                                    This Privacy Policy deals with information We collect in relation to Our Sites and
                                    explains:
                                    <br />
                                    a. The type of Information collected from User;
                                    <br />
                                    b. How We collect and use that Information; and
                                    <br />
                                    c. How We process, share and protect that Information;
                                </Paragraph>
                                <Header4>CONSENT</Header4>
                                <Paragraph>
                                    You acknowledge that You are providing Your Information out of Your free will. You
                                    hereby consent to Our collection, recording, storage, use, sharing and disclosure of
                                    Your Information as described in this Privacy Policy. If You do not agree with this
                                    Privacy Policy at any time, do not use any of the Services or give Us any of Your
                                    information.
                                </Paragraph>
                                <Paragraph>
                                    You hereby represent that You are competent to contract. If You use the Services for
                                    and/or on behalf of someone else (such as Your child and/or any person who is
                                    incompetent to enter into an agreement), You represent that You are authorized by
                                    such individual to represent that individual and (i) accept this Privacy Policy on
                                    such individual's behalf, and (ii) consent on behalf of such individual to Our
                                    collection, recording, storage, use and disclosure of such individual's Information
                                    as described in this Privacy Policy.
                                </Paragraph>
                                <Paragraph>
                                    If You are accessing or using the Sites from any location outside India, You do so
                                    at Your own risk, and will be solely liable for compliance with any local laws, as
                                    may be applicable.
                                </Paragraph>
                                <Header4>INFORMATION WE COLLECT</Header4>
                                <Paragraph>
                                    For the purpose of this Policy, Information collected from the User is categorized
                                    as Personal Information and Sensitive Personal Data/Information. “Personal
                                    Information” shall mean and include without limitation any information that can be
                                    used to uniquely identify, contact or locate a person or can be made available from
                                    other sources to uniquely identify, contact or locate a person, information/content
                                    that is posted, stored, saved, downloaded, uploaded or is otherwise made available
                                    by the User. “Sensitive Personal Data/Information” shall include password, financial
                                    information, biometric information, step count data, medical records and history and
                                    any other details classified so by Us for using Our Sites. Personal Information and
                                    Sensitive Personal Data/Information shall sometimes collectively be referred to as
                                    <Strong>“Information”</Strong> in this Policy.
                                </Paragraph>
                                <Paragraph>
                                    We may collect Information that You submit to Us which include name, email address,
                                    login ID and password, home address, date of birth, gender, phone number, height,
                                    weight, health or medical history and data, insurance data, Company details,
                                    demographic information such as postcode, location, preferences and interests, and
                                    other information relevant to patients, health insurance, surveys and/or offers and
                                    other related information. We may also seek permissions to use camera, microphone,
                                    and phonebook/contact data at points where required. We collect this information to
                                    provide customer support, service booking and insurance support.
                                </Paragraph>
                                <Paragraph>
                                    When You view or download content from Our Sites, We may automatically collect and
                                    store certain information such as internet protocol (IP) address, hardware settings,
                                    browser type, browser language, cookies, data caches or any other related
                                    information. We may collect and store device-related Information to help Us diagnose
                                    problems with Our server, resolve technical difficulties, deliver improved
                                    functionalities of Our Sites, provide relevant Services, promote/advertise Services,
                                    gather demographic information about Our User base and prevent the transmission of
                                    spam or viruses. We do not collect and store this information for any other purpose.
                                </Paragraph>
                                <Paragraph>
                                    We also may collect information about Your activity on Our Sites, which might
                                    include frequency of visits to Our web site, average length of visits and pages
                                    viewed during a visit.
                                </Paragraph>
                                <Paragraph>
                                    You understand that Your feedbacks, comments, ratings and any other related content
                                    You post on Our Sites or on any App store on any social media Sites or any other
                                    publicly accessible Sites relating to Us, Our Sites and/or Our Services forms part
                                    of public domain and are accessible to the public at large. All such sharing of Your
                                    information is done at Your own risk. We own all the rights, titles and interests in
                                    all such feedbacks, comments, ratings and any other related content You post as
                                    aforementioned and can use them in any manner at Our sole discretion.
                                </Paragraph>
                                <Header4>HOW WE COLLECT THE INFORMATION</Header4>
                                <Paragraph>
                                    The methods by which We collect Your Information includes but are not limited to-
                                </Paragraph>
                                <ol start="">
                                    <li>when You register on Our Sites;</li>
                                    <li>when You create a profile on Our Sites or as part of Our Services;</li>
                                    <li>
                                        when Your employer shares your Information such as name, contact number, date of
                                        birth, gender and insurance policy details with Us;
                                    </li>
                                    <li>
                                        when You provide Your Information to Us including without limitation through
                                        chats, phone consultation, visits to Our clinics, Our insurance partners and
                                        Third party Administrators, participation in survey, contest, program or event;
                                    </li>
                                    <li>when You use the features on Our Sites and/or when You use Our Services;</li>
                                    <li>when You access the Sites or Services and</li>
                                    <li>by use of cookies</li>
                                    <li>
                                        when you choose to connect your account on Our Services to Your account on
                                        another application/service, We may receive information from other
                                        service/application.
                                    </li>
                                </ol>
                                <Header4>HOW WE USE THE INFORMATION</Header4>
                                <Paragraph>
                                    We may use the Information that is collected from You to provide requested products
                                    and Services and for Our internal business purposes, including, without limitation:
                                </Paragraph>
                                <ol start="">
                                    <li>
                                        Provide and improve Our Services and improve Your browsing experience by
                                        customization of Sites;
                                    </li>
                                    <li>Registration of the User on the Sites;</li>
                                    <li>
                                        Complete transactions with the User effectively and billing for the Services
                                        provided;
                                    </li>
                                    <li>Delivery of personalized information;</li>
                                    <li>Verification of identity of Users and perform checks to prevent frauds;</li>
                                    <li>
                                        Help You address Your problems incurred on the Sites including addressing any
                                        technical problems;
                                    </li>
                                    <li>Internal record keeping;</li>
                                    <li>Contact You;</li>
                                    <li>
                                        Send You information about additional clinical services, general wellness,
                                        insurance policies and benefits which We think may be of interest to You either
                                        directly from Us and/or on behalf of Our affiliates and Third Party Service
                                        Providers;
                                    </li>
                                    <li>
                                        Send marketing communications relating to Our business or the businesses of
                                        selected third parties which We think may be of interest to You;
                                    </li>
                                    <li>
                                        Conduct research using Your information, which may be subject to Your separate
                                        written authorization;
                                    </li>
                                    <li>
                                        To access, read, preserve, and disclose any Information that We reasonably
                                        believe is necessary to comply with law or court order; enforce or apply Invoq's
                                        Terms of Use and other policies/agreements; or protect the rights, property, or
                                        safety of Invoq. This includes exchanging information with other companies and
                                        organizations for fraud protection and credit risk reduction;
                                    </li>
                                    <li>debugging customer support related issues;</li>
                                    <li>to consult with another medical practitioner or a specialist;</li>
                                    <li>
                                        Performing other functions as described to You at the time of collection or
                                        pursuant to Your consent.
                                    </li>
                                </ol>
                                <Paragraph>‍</Paragraph>
                                <Paragraph>
                                    You may request details of Your personal information which We use/process about You
                                    by writing to or emailing us at
                                    <a href="http://info@loophealth.com"> info@loophealth.com</a>
                                </Paragraph>
                                <Header4>DISCLOSURE OF INFORMATION</Header4>
                                <Paragraph>
                                    We are committed to maintaining Your trust, and We want You to understand when and
                                    with whom We may share the information We collect. We may disclose Your Information
                                    to-
                                </Paragraph>
                                <ol start="">
                                    <li>Our affiliates and Third Party Service Providers;</li>
                                    <li>
                                        Medical Specialist/s, doctors, laboratories, government agencies, insurance
                                        companies, organ procurement organizations, medical examiners or other entities
                                        relevant to providing You with treatment options ,support and other Services.
                                    </li>
                                    <li>
                                        Our research partners to conduct health-related research (
                                        <em>such Information is anonymised and aggregated</em>)
                                    </li>
                                    <li>
                                        We may share Your Information in connection with a substantial corporate
                                        transaction, such as the sale of Our Sites, merger, acquisition, consolidation,
                                        asset sale, or in the unlikely event of bankruptcy or winding up.
                                    </li>
                                    <li>
                                        Respond to subpoenas, court orders, legal process, law enforcement requests,
                                        legal claims or government inquiries and to protect and defend the rights,
                                        interests, health, safety, and security of Invoq, Our affiliates or the public.
                                        If We are legally compelled to disclose Information about You to a third party,
                                        we will attempt to notify You by sending an email to the email address in Our
                                        records unless doing so would violate the law and/or unless You have not
                                        provided Your email address to Us.
                                    </li>
                                    <li>
                                        We reserve Our right to investigate complaints or reported violations of the
                                        Privacy Policy and Terms of Use and to take any action We deem appropriate. Such
                                        action may include but not be limited to reporting any unlawful activity to law
                                        enforcement officials, regulators or other third parties. In addition, We may
                                        take action to disclose any Information necessary or appropriate to such persons
                                        or entities.
                                    </li>
                                    <li>
                                        We may share Information for fulfilling the purposes disclosed to You at the
                                        time We collect the information or pursuant to Your consent or direction.
                                    </li>
                                    <li>
                                        We may share information for enforcing Our Terms of Use and/or any other
                                        agreements, policies, including for billing and collection purposes.
                                    </li>
                                </ol>
                                <Header4>SECURITY</Header4>
                                <Paragraph>
                                    We are committed to ensure that Your Information is secure. In order to prevent
                                    unauthorized access or disclosure, We have endeavoured to put in place best
                                    international market practices and technical measures and suitable physical,
                                    electronic and managerial procedures to safeguard and secure the Information We
                                    collect through Our Sites. We restrict access of Information, to Our employees,
                                    affiliates, agents and service providers who need to know that information in order
                                    to process it for Us, and who are subject to strict contractual confidentiality
                                    obligations, and who may be disciplined or whose relationship with Us may terminate,
                                    if they fail to meet these obligations.
                                </Paragraph>
                                <Paragraph>
                                    While We try Our best to provide security that is commensurate with the industry
                                    standards, because of the inherent vulnerabilities, we cannot ensure or warrant
                                    complete security of all Information that is being transmitted to Us by You.
                                    Further, We shall not be responsible for any breach of security or for any actions
                                    of any third parties or events that are beyond Our reasonable control including but
                                    not limited to acts of government, computer hacking, unauthorised access to computer
                                    data and storage device, computer crashes, breach of security and encryption, etc.
                                </Paragraph>
                                <Paragraph>
                                    The User is responsible for maintaining the confidentiality of the User's account,
                                    access information and password. The User shall be responsible for all uses of the
                                    User's account and password, whether or not authorized by the User. The User shall
                                    immediately notify Invoq of any actual or suspected unauthorized use of the User's
                                    account or password.
                                </Paragraph>
                                <Header4>RETENTION OF INFORMATION</Header4>
                                <Paragraph>
                                    We will retain Your Information for as long as Your account is active with Us to
                                    provide the Services. We shall not retain such Information for longer than as
                                    required for the purposes for which the Information has been acquired or as may be
                                    required under the applicable law for the time being in force. After a period of
                                    time, Your data may be anonymized and aggregated, and then may be held by Us as long
                                    as necessary for Us to provide Services effectively and/or improve the Services, but
                                    Our use of the anonymized data will be solely for analytic purposes.
                                </Paragraph>
                                <Header4>LINKS TO OTHER WEBSITES</Header4>
                                <Paragraph>
                                    Our Sites may contain links to other third party websites/App
                                    <Strong>(“Third Party Website”)</Strong>. However, once You have used these links to
                                    leave Our Sites, You should note that We do not have any control over that other
                                    Third Party Websites nor the nature, content and availability of those Third Party
                                    Websites. Therefore, We cannot be responsible for the protection and privacy of any
                                    Information which You provide whilst visiting such Third Party Websites and such
                                    Third Party Websites are not governed by this Privacy Policy. You should exercise
                                    caution and look at the privacy policies applicable to the Third Party Websites You
                                    visit. The inclusion of any links on Our Sites does not imply a recommendation or
                                    endorsement of the products, services, information and/or views expressed within
                                    them.
                                </Paragraph>
                                <Header4>COOKIES</Header4>
                                <Paragraph>
                                    Cookies are small text files which ask for permission to be placed on Your
                                    computer's hard drive. Once You agree, these files identify Your computer and
                                    analyzes web traffic or lets You know when You visit a particular site and records
                                    Your preferences and can tailor its operations to Your needs, likes and dislikes by
                                    gathering and remembering information about Your preferences. We use cookies to
                                    collect information so that We can determine how to improve our Sites by seeing
                                    which areas, features and products are most popular, to personalize the Sites and to
                                    improve the User's experience of using Our Sites. Overall, cookies help us provide
                                    You with better content, by enabling Us to monitor which pages You find useful and
                                    which You do not. Most browsers are automatically set to accept cookies. You can
                                    disable cookies or set Your browser to alert You when cookies are being sent.
                                    However, some areas of Our Sites may not function properly if You do so.
                                </Paragraph>
                                <Header4>CORRECTING INACCURACIES OR UPDATING INFORMATION</Header4>
                                <Paragraph>
                                    If any of Your Information changes, or if You need to update or correct Your
                                    Information, You may send updates and corrections with appropriate supporting
                                    documents to Us at the contact Us button in the Loop Health application or at
                                    info@loophealth.com or calling us at 9511949475. You may also share the updates and
                                    corrections to the medical advisor assigned to You. We will take all reasonable
                                    efforts to incorporate the changes within a reasonable period of time.
                                </Paragraph>
                                <Header4>DISCLAIMER</Header4>
                                <Paragraph>
                                    We shall not be responsible in any manner for the authenticity of the Information
                                    supplied by the User to Us or Our Affiliates or Third Party Service Providers, as
                                    the case may be. If a User provides any Information that is untrue, inaccurate, not
                                    current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or
                                    We have reasonable grounds to suspect that such Information is untrue, inaccurate,
                                    not current or incomplete, We have the right to suspend or terminate such User's
                                    account at Our sole discretion.
                                </Paragraph>
                                <Paragraph>
                                    We cannot ensure that all of Your Information including Personal Information will
                                    never be disclosed in ways not otherwise described in this Privacy Policy. As a User
                                    of the Sites, You assume all responsibility and risk for Your use of the Sites, the
                                    internet generally, and the information You post or access and for Your conduct on
                                    and off the Sites.
                                </Paragraph>
                                <Header4>GOVERNING LAW </Header4>
                                <Paragraph>
                                    This Privacy Policy shall be governed by and interpreted and construed in accordance
                                    with the laws of India. The place of jurisdiction shall be exclusively with the
                                    courts at Pune, Maharashtra, India.
                                </Paragraph>
                                <Header4>SEVERABILITY</Header4>
                                <Paragraph>
                                    In the event that any provision of these terms is held unenforceable, the validity
                                    or enforceability of the remaining provisions will not be affected, and the
                                    unenforceable provision will be replaced with an enforceable provision that comes
                                    close to the intention underlying the unenforceable provision.
                                </Paragraph>
                                <Header4>GRIEVANCE REDRESSAL AND QUERIES</Header4>
                                <Paragraph>
                                    If You have any questions, complaints or grievance about this Privacy Policy or any
                                    other concerns about the way in which we process information about You or in
                                    relation to any content posted on our Platform, You may contact us at
                                    <AnchorWrapper href="mailto:info@loophealth.com">
                                        {' '}
                                        info@loophealth.com
                                    </AnchorWrapper>
                                    .You may contact our customer support at
                                    <AnchorWrapper href="mailto:info@loophealth.com">
                                        {' '}
                                        info@invoqhealth.com
                                    </AnchorWrapper>
                                    You may also approach our Grievance Officer, Mr. Ryan Singh, Invoq Healthcare India
                                    Private Limited, Flat No. 9, Plot No. 17, Nishigandha S B Road, Near Navrajasthan
                                    Society, Pune 411016; e-mail:
                                    <AnchorWrapper href="mailto:info@loophealth.com">
                                        {' '}
                                        info@invoqhealth.com
                                    </AnchorWrapper>
                                </Paragraph>
                                We will use reasonable efforts to respond promptly to any requests, questions or
                                concerns, which You may have regarding our use of Your personal information.
                                <br />
                                <br />
                                The process to address the grievance will be as mentioned hereunder:
                                <ol start="">
                                    <li>
                                        If the Complaint/grievance is received through email, call, SMS, post or through
                                        any other means other than walk-in, its receipt shall be acknowledged within 24
                                        hours;
                                    </li>
                                    <li>
                                        All grievances from walk-in Users/customers will be acknowledged on the same
                                        day.
                                    </li>
                                    <li>
                                        All couriers/posts will be answered/acknowledged from the registered office as
                                        and when received
                                    </li>
                                    <li>
                                        A reference number of the complaint/grievance will be shared with the
                                        User/customer through which the status of the compliant/grievance can be
                                        tracked.
                                    </li>
                                    <li>
                                        The grievance/complaint shall be routed to the concerned person. Resolution of
                                        such complaint/grievance will be done within 15 working days.
                                    </li>
                                    <li>
                                        All grievances will be redressed at the earliest but not later than one month
                                        from the date of receipt of the grievance/complaint.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </TermsUseWrapper>
            </TermsWrapper>
        </Wrapper>
    );
};
