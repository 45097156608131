import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../services/network/LoopApiService';

const CACHE_TIME = 30 * 60 * 1000;

export const fetchSpecialityList = createAsyncThunk(
  'speciality/getSpecialityList',
  async () => {
    const specialities = await LoopApiService.getSpecialities();
    return {
      data: specialities,
    };
  },
  {
    condition: (_, { getState }) => {
      const { speciality } = getState();

      // Check if cache exists
      if (speciality.data) {
        const specialityData = speciality.data;
        if (Object.keys(specialityData).length !== 0) {
          const lastFetched = specialityData.lastFetched;
          if (
            Date.now() - lastFetched < CACHE_TIME &&
            speciality.data.length !== 0
          ) {
            return false;
          }
        }
      }
    },
  },
);

const specialitySlice = createSlice({
  name: 'speciality',
  initialState: {
    data: {
      data: [],
    },
    status: 'idle',
    error: '',
  },
  reducers: {},
  extraReducers: {
    [fetchSpecialityList.pending]: (state, action) => {
      state.status = 'loading';
      state.error = '';
    },
    [fetchSpecialityList.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = {
        data: action.payload.data || [],
        lastFetched: Date.now(),
      };
    },
    [fetchSpecialityList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default specialitySlice.reducer;
