import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getDay from 'date-fns/getDay';
import startOfToday from 'date-fns/startOfToday';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import set from 'date-fns/set';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import add from 'date-fns/add';
import LoopApiService from '../../services/network/LoopApiService';

export const fetchOnCallDoctorDates = createAsyncThunk(
  'slotDates/getAvailableDates',
  async ({ doctorId }) => {
    const result = await LoopApiService.getSlotDates(doctorId);
    return {
      key: doctorId,
      data: result,
    };
  }
);

const slotDates = createSlice({
  name: 'slotDates',
  initialState: {
    data: {},
    status: 'idle',
    error: '',
  },
  reducers: {},
  extraReducers: {
    [fetchOnCallDoctorDates.pending]: (state, action) => {
      state.status = 'loading';
      state.error = '';
    },
    [fetchOnCallDoctorDates.fulfilled]: (state, action) => {
      let daysFromNow = [];
      if (action.payload.data && action.payload.data.length !== 0) {
        state.status = 'succeeded';
        const currentTime = new Date();
        const dayOfWeek = getDay(currentTime);

        // Get days of week from now
        const sliceIndex = action.payload?.data?.findIndex(
          (item) => item.day >= dayOfWeek
        );
        if (sliceIndex > -1) {
          daysFromNow = action.payload.data.slice(sliceIndex);
        }

        // Check if slots are available today
        const timings = daysFromNow[0].timings;
        let slotEndTime = startOfToday();
        for (const timeObj of timings) {
          let tempTime = parse(timeObj.endTime, 'h:mm a', new Date());
          if (isAfter(tempTime, slotEndTime)) {
            slotEndTime = tempTime;
          }
        }

        const slotDay = daysFromNow[0].day;
        let endTimeParsed = parse(`${slotDay + 1}`, 'e', slotEndTime);
        endTimeParsed = set(endTimeParsed, {
          hours: getHours(slotEndTime),
          minutes: getMinutes(slotEndTime),
        });
        if (isAfter(currentTime, endTimeParsed)) {
          daysFromNow.shift();
        }

        // Create at least 7 days
        daysFromNow = daysFromNow.map((item) =>
          (
            getUnixTime(
              add(startOfDay(parse(item.day + 1, 'e', new Date())), {
                hours: 5,
                minutes: 30,
              })
            ) * 1000
          ).toString()
        );
        let index = 0;
        let nextWeek = 7;
        while (daysFromNow.length <= 7) {
          const currentDayOfWeek = action.payload.data[index].day;
          daysFromNow.push(
            (
              getUnixTime(
                add(
                  startOfDay(
                    add(parse(currentDayOfWeek + 1, 'e', new Date()), {
                      days: nextWeek,
                    })
                  ),
                  {
                    hours: 5,
                    minutes: 30,
                  }
                )
              ) * 1000
            ).toString()
          );
          index += 1;
          if (index >= action.payload.data.length) {
            nextWeek += 7;
            index = 0;
          }
        }
      } else {
        state.status = 'failed';
        state.error = 'No available slots';
      }
      state.data[action.payload.key] = {
        data: daysFromNow || [],
        lastFetched: Date.now(),
      };
    },
    [fetchOnCallDoctorDates.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectDatesByDoctorId = (state, doctorId) =>
  state.slotDates.data[doctorId];

export default slotDates.reducer;
