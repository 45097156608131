import React from 'react';
import {
    StyledContainer,
    StyledInfoContainer,
    StyledAlignContainer,
    StyledSubtitle,
    StyledImage,
    StyledMainContainer,
    StyledButtonContainer,
    StyledButtonAlignment,
    StyledHeader
} from './styles';
import Header from '../../components/LoopHeader';
import { useNavigate } from 'react-router-dom';
import { Typography, LoopButton } from '@loophealth/loop-ui-web-library';
import { AppFrame, AndroidIcon, IOSIcon } from '../../assets/images';

const LandingContainer: React.FunctionComponent = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    const handleClick = (type: 'Android' | 'IOS') => {
        if (type === 'Android') {
            const packageName = 'com.invoqhealth.loophealth.patient';
            const playStoreUrl = 'market://details?id=' + packageName;
            window.location.href = playStoreUrl;
        } else if (type === 'IOS') {
            const appId = '1495161232';
            const appStoreUrl = 'https://apps.apple.com/app/id' + appId;
            window.location.href = appStoreUrl;
        }
    };

    return (
        <StyledMainContainer>
            <StyledContainer>
                <StyledHeader>
                    <Header goHome={goHome} />
                </StyledHeader>
                <StyledAlignContainer>
                    <StyledInfoContainer>
                        <Typography variant="titleLarge" weight="regular" color="tertiary">
                            Download the{' '}
                            <Typography variant="titleLarge" weight="regular" color="green">
                                Loop
                            </Typography>
                            <br /> App today
                        </Typography>

                        <StyledSubtitle>
                            <Typography variant="title2" weight="regular" color="tertiary">
                                Access your policy cards and our panel <br />
                                of doctors by downloading <br /> the app today
                            </Typography>
                        </StyledSubtitle>

                        <StyledButtonAlignment>
                            <StyledButtonContainer>
                                <LoopButton
                                    variant="filled"
                                    onClick={() => handleClick('Android')}
                                    iconSrc={AndroidIcon}
                                    iconOrder="left"
                                >
                                    <Typography variant="medium" weight="regular" color="emerald">
                                        Download for Android
                                    </Typography>
                                </LoopButton>
                            </StyledButtonContainer>

                            <StyledButtonContainer>
                                <LoopButton
                                    variant="filled"
                                    onClick={() => handleClick('IOS')}
                                    iconSrc={IOSIcon}
                                    iconOrder="left"
                                >
                                    <Typography variant="medium" weight="regular" color="emerald">
                                        Download for IOS
                                    </Typography>
                                </LoopButton>
                            </StyledButtonContainer>
                        </StyledButtonAlignment>
                    </StyledInfoContainer>

                    <StyledImage src={AppFrame} />
                </StyledAlignContainer>
            </StyledContainer>
        </StyledMainContainer>
    );
};

export default LandingContainer;
