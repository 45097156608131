import styled from 'styled-components';

export const StyledMainContainer = styled.div`
    background-color: ${(p) => p.theme.colors.primaryBG};
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    max-height: 100vh;
    background-color: ${(p) => p.theme.colors.primaryBG};
    padding: 55px 100px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 1300px) {
        padding: 0px;
        height: 100%;
        max-height: 100%;
    }
`;

export const StyledHeader = styled.div`
    @media (max-width: 1300px) {
        margin-top: 20px;
    }
`;

export const StyledAlignContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100vw;
    flex-grow: 1;
    flex-shrink: 0;
    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const StyledInfoContainer = styled.div`
    padding-top: 50px;
    margin-right: 150px;
    @media (max-width: 1300px) {
        margin-right: 0px;
        text-align: center;
    }
`;

export const StyledSubtitle = styled.div`
    margin-top: 50px;
    @media (max-width: 1300px) {
        max-width: 100vw;
    }
`;

export const StyledImage = styled.img`
    height: 546px;
    width: 561px;
    @media (max-width: 1300px) {
        width: 80vw;
        height: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        align-self: center;
    }
`;

export const StyledButtonAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 60px;
    @media (max-width: 1300px) {
        flex-direction: column;
        height: 150px;
        align-items: center;
        margin-top: 30px;
    }
`;

export const StyledButtonContainer = styled.div`
    width: 251px;
    margin-right: 15px;
    @media (max-width: 1300px) {
        align-items: flex-start;
    }
`;
