import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
`;

const PolicyTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #3e4a59;
`;

const PolicySubTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #667985;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const TermsWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  padding-bottom: 40vh;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 26px;
    background: #e6eff0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 26px;
    background: #18a294;
    width: 20px;
  }
`;
const TermsTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  color: #595959;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const TermsUseWrapper = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #595959;
`;

const Paragraph = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #595959;
`;

const AnchorWrapper = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #025f4c;
  text-decoration: underline;
`;

const Header4 = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #595959;
`;

const Strong = styled.strong`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #595959;
`;

export {
  Wrapper,
  PolicyTitle,
  PolicySubTitle,
  TermsUseWrapper,
  TermsTitle,
  TermsWrapper,
  AnchorWrapper,
  Header4,
  Strong,
  Paragraph,
};
