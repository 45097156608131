import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

const doctorsAdapter = createEntityAdapter({
  selectId: (doctor) => doctor.id,
});

export const fetchDoctorListBySpeciality = createAsyncThunk(
  "doctor/getDoctorListBySpeciality",
  async ({ speciality }) => {
    const [
      result,
      headerUrls,
      detailUrls,
    ] = await LoopApiService.getDoctorBySpeciality(speciality);
    return { data: result, headerUrls, detailUrls };
  },
);

export const fetchDoctorList = createAsyncThunk(
  "doctor/getDoctorList",
  async () => {
    const result = await LoopApiService.getDoctorList();
    return result;
  }
);

export const fetchDoctorById = createAsyncThunk(
  "doctor/getDoctorById",
  async ({ doctorId }) => {
    const result = LoopApiService.getDoctorById(doctorId);
    return result;
  },
  {
    condition: (doctorId, { getState }) => {
      const { doctors } = getState();
      if (doctors.ids.includes(doctorId)) {
        return false;
      }
    }
  },
);

const doctorSlice = createSlice({
  name: "doctor",
  initialState: doctorsAdapter.getInitialState({
    status: "idle",
    error: "",
  }),
  reducers: {},
  extraReducers: {
    [fetchDoctorList.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchDoctorList.fulfilled]: (state, action) => {
      doctorsAdapter.upsertMany(state, action.payload);
      state.status = "succeeded";
    },
    [fetchDoctorList.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchDoctorById.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchDoctorById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      doctorsAdapter.upsertOne(state, action.payload);
    },
    [fetchDoctorById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const doctorSelectors = doctorsAdapter.getSelectors(state => state.doctors);
export const selectDoctorsBySpeciality = specialityId => createSelector(
  doctorSelectors.selectAll,
  doctors => doctors.filter(item => (item.specialityId === specialityId) && (item.active === true)),
);
export const selectDoctorById = (state, doctorId) => doctorSelectors.selectById(state, doctorId);
export const selectDoctorEntities = (state) => doctorSelectors.selectEntities(state);

export default doctorSlice.reducer;
