import ExternalRouter from './pages/router';

const App = () => {
    return (
        <div className="App">
            <ExternalRouter />
        </div>
    );
};

export default App;
