import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import LoopApiService from "../../services/network/LoopApiService";

export const fetchOnCallDoctorSlots = createAsyncThunk(
  "slots/getOnCallDoctor",
  async ({ doctorId, date }) => {
    const result = await LoopApiService.getSlots(doctorId, date);
    const dataKey = doctorId + date.toString();
    return { key: dataKey, data: result };
  }
);

const slotsSlice = createSlice({
  name: "slots",
  initialState: {
    data: {},
    status: "idle",
    error: "",
    slotsAvailable: "",
  },
  reducers: {},
  extraReducers: {
    [fetchOnCallDoctorSlots.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchOnCallDoctorSlots.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (
        action.payload.data.length !== 0 &&
        Object.keys(action.payload.data).length !== 0
      ) {
        const morning = [];
        const afternoon = [];
        const evening = [];

        action.payload.data.forEach((timestamp) => {
          const time = Object.keys(timestamp)[0];
          const hour = format(toDate(parseInt(time, 10)), 'H');
          const offset = Math.floor(hour / 3);

          if (offset < 4) {
            morning.push(timestamp);
          } else if (offset < 6) {
            afternoon.push(timestamp);
          } else {
            evening.push(timestamp);
          }
        });

        const timeSlots = [];
        if (morning.length !== 0) {
          timeSlots.push({
            key: 1,
            date: 'Morning',
            data: morning,
          });
        }
        if (afternoon.length !== 0) {
          timeSlots.push({
            key: 2,
            date: 'Afternoon',
            data: afternoon,
          });
        }
        if (evening.length !== 0) {
          timeSlots.push({
            key: 3,
            date: 'Evening',
            data: evening,
          });
        }
        state.data[action.payload.key] = {
          data: timeSlots,
          lastFetched: Date.now(),
        };
      } else {
        state.data[action.payload.key] = {
          data: [],
          lastFetched: Date.now(),
        }
        state.slotsAvailable = 'No slots available';
      }
    },
    [fetchOnCallDoctorSlots.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectSlotsByKey = (state, dataKey) => state.slots.data[dataKey];

export default slotsSlice.reducer;
