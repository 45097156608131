import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

const visitsAdapter = createEntityAdapter({
  selectId: (visit) => visit.id,
  sortComparer: (a, b) =>
    a.timestamp.seconds > b.timestamp.seconds
      ? -1
      : a.timestamp.seconds < b.timestamp.seconds
      ? 1
      : 0,
});

export const fetchAllVisits = createAsyncThunk(
  "visits/getAllVisits",
  async ({ patientId }) => {
    const [visits, doctors] = await LoopApiService.getVisits(patientId);
    return {
      visits,
      doctors,
    };
  }
);

const visitSlice = createSlice({
  name: "visits",
  initialState: visitsAdapter.getInitialState({
    status: "idle",
    error: "",
    doctors: [],
  }),
  reducers: {
    cancelVisit(state, action) {
      visitsAdapter.updateOne(state, {
        id: action.payload.visitId,
        changes: { status: action.payload.status },
      });
    },
  },
  extraReducers: {
    [fetchAllVisits.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchAllVisits.fulfilled]: (state, action) => {
      visitsAdapter.setAll(state, action.payload.visits);
      state.doctors = action.payload.doctors;
      state.status = "succeeded";
    },
    [fetchAllVisits.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { cancelVisit } = visitSlice.actions;

export const visitSelectors = visitsAdapter.getSelectors(
  (state) => state.visits
);
export const selectUpcomingVisits = () =>
  createSelector(visitSelectors.selectAll, (visits) => {
    const currentTime = Date.now();
    return visits.filter(
      (item) =>
        item.timestamp.seconds * 1000 >= currentTime &&
        item.status !== "cancelled"
    );
  });
export const selectPastVisits = () =>
  createSelector(visitSelectors.selectAll, (visits) => {
    const currentTime = Date.now();
    return visits.filter(
      (item) =>
        item.timestamp.seconds * 1000 < currentTime &&
        item.status !== "cancelled"
    );
  });

export default visitSlice.reducer;
