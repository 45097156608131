import { combineReducers } from 'redux';
import userSlice from './slices/userSlice';
import userProfileSlice from './slices/userProfileSlice';
import doctorSlice from './slices/doctorSlice';
import specialitySlice from './slices/specialitySlice';
import visitSlice from './slices/visitSlice';
import slotDatesSlice from './slices/slotDatesSlice';
import slotsSlice from './slices/slotsSlice';
import policyPlanSlice from './slices/policyPlanSlice';
import dependentsSlice from './slices/dependentsSlice';
import crudAppointmentSlice from './slices/crudAppointmentSlice';

const rootReducer = combineReducers({
  user: userSlice,
  userProfile: userProfileSlice,
  doctors: doctorSlice,
  speciality: specialitySlice,
  visits: visitSlice,
  slotDates: slotDatesSlice,
  slots: slotsSlice,
  policyPlan: policyPlanSlice,
  dependents: dependentsSlice,
  crudAppointment: crudAppointmentSlice,
});

export default rootReducer;
