import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../services/network/LoopApiService';

export const bookAppointment = createAsyncThunk('bookAppointment/bookAppointment', async ({ data }) => {
    try {
        const result = await LoopApiService.bookDoctorSlot(data);
        return {
            data: result
        };
    } catch (e) {
        throw e;
    }
});

const crudAppointmentSlice = createSlice({
    name: 'crudAppointment',
    initialState: {
        data: null,
        status: 'idle',
        error: ''
    },
    reducers: {
        resetState(state) {
            state.data = null;
            state.status = 'idle';
            state.error = '';
        }
    },
    extraReducers: {
        [bookAppointment.pending]: (state, action) => {
            state.status = 'loading';
            state.error = '';
        },
        [bookAppointment.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.status = 'succeeded';
        },
        [bookAppointment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        }
    }
});

export default crudAppointmentSlice.reducer;
export const { resetState } = crudAppointmentSlice.actions;
