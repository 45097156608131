import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

export const fetchUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async ({ userId }) => {
    const result = await LoopApiService.getUserProfile(userId);
    return result;
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    profile: null,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchUserProfile.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchUserProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
      state.status = "succeeded";
    },
    [fetchUserProfile.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectUserProfile = (state) => state.userProfile.profile;
export const selectUserId = (state) =>
  state.userProfile.profile ? state.userProfile.profile.id : null;
export const selectUserEcard = (state) =>
  state.userProfile.profile ? state.userProfile.profile.patientEcardUrl : null;
export const selectUserPolicyPlan = (state) =>
  state.userProfile.profile ? state.userProfile.profile.policyPlan : null;
export const selectUserName = (state) =>
  state.userProfile.profile
    ? `${state.userProfile.profile.firstName} ${state.userProfile.profile.lastName}`
    : null;
export const selectEmployerId = (state) =>
  state.userProfile.profile ? state.userProfile.profile.employer : null;

export default userProfileSlice.reducer;
