import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

export const fetchDependents = createAsyncThunk(
  "dependents/getDependentsInformation",
  async ({ userId }) => {
    const result = await LoopApiService.getDependentInformation(userId);
    return {
      data: result,
    };
  }
);

const dependentSlice = createSlice({
  name: "dependents",
  initialState: {
    data: null,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchDependents.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchDependents.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.status = "succeeded";
    },
    [fetchDependents.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectDependents = (state) =>
  state.dependents.data
    ? state.dependents.data.length !== 0
      ? state.dependents.data
      : null
    : null;

export default dependentSlice.reducer;
